import React, {useEffect, useState} from "react";
import './UserTickets.css';
import {useParams} from "react-router-dom";
import ApiInterface from "@/commons/api/ApiInterface"
import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";
import Redirects from "@/commons/utils/Redirects"
import {environment} from "@/commons/configs/Config";

const UserTickets = () => {
    let {id} = useParams();
    let [user, setUser] = useState();

    async function viewData() {
        const rb = await ApiInterface.getBadge(user.role.alias);
        const roleBadge = await rb.text();
        const imgSrc = `data:image/png;base64,${roleBadge}`;

        const usView = (
            <div className="user_tickets-badge-username">
                <img src={imgSrc} alt="roleBadge" className="user_tickets-role-badge"/>
                <span className="user_tickets-username selectable">{user.username}</span>
            </div>
        );
        setUserView(usView);

        const ticketsInfo = await ApiInterface.getLightUserPosts(user.id, 0, 1);
        if (ticketsInfo.status !== 200) {
            return;
        }
        const ticketsJson = await ticketsInfo.json();
        const totalTickets = ticketsJson.totalElements;

        setTotalTickets(totalTickets);

        for (let i = totalTickets - 1; i >= 0; i--) {
            const ticketInfo = await ApiInterface.getLightUserPosts(user.id, i, 1);
            const ticketJson = await ticketInfo.json();
            const ticket = ticketJson.content[0];

            const ticket_id = ticket.id;
            const ticket_title = ticket.title;
            const ticket_status = ticket.status;
            const ticket_time = ticket.time;

            const ticket_category = ticket.categoryId.name;

            const ticketElement = (
                <div className={"user_tickets-ticket"} onClick={(e) => {
                    Redirects.send(environment.reportsUrl + "post/" + ticket_id, e)
                }}>
                    <span className={"user_tickets-ticket-id"}>#{ticket_id} - </span><span
                    className={"user_tickets-ticket-status"}
                    dangerouslySetInnerHTML={formatStatus(ticket_status)}></span>
                    <div className={"user_tickets-ticket-title"}>{ticket_title}</div>
                    <div className={"user_tickets-ticket-category"}>{ticket_category}</div>
                    <div className={"user_tickets-ticket-time"}>{formatDateTime(ticket_time)}</div>
                </div>
            );

            setTickets(oldTickets => [...oldTickets, ticketElement]);
        }
    }

    function formatDateTime(dateString) {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }

    function formatStatus(status) {
        status = status.toUpperCase();
        if (status === "OPEN") {
            return {__html: "<span class='status-green'>Aperto</span>"};
        } else if (status === "CLOSED") {
            return {__html: "<span class='status-red'>Chiuso</span>"};
        } else {
            return {__html: `<span class='status-gray'>${status}</span>`};
        }
    }

    useEffect(() => {
        async function loadUser() {
            const tUser = await ApiInterface.getUser(id);
            const u = await tUser.json();
            setUser(u);
        }

        loadUser();
    }, [id]);

    useEffect(() => {
        if (user != null) {
            viewData();
        }
    }, [user]);

    const [userView, setUserView] = useState(null);
    const [tickets, setTickets] = useState([]);
    const [totalTickets, setTotalTickets] = useState("Questo utente non ha ticket");

    return (
        <>
            <Background/>
            <div className={"page__body"}>
                <Header marginTop={1} marginBottom={1}/>
                <div className={"page__content"}>
                    <div className={"info-container"}>
                        <h1 className={"info-container-title"}>Tickets Utente: {totalTickets}</h1>
                        {userView}
                        <button onClick={(e) => {
                            Redirects.domainSendClick("user/" + user.id, e)
                        }} className={"user_tickets-back-button"}>⬅ Indietro
                        </button>
                        <div className={"user_tickets-ticketsSection"} id={"tickets-section"}>
                            {tickets}
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    );
};

export default UserTickets;
